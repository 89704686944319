import React from "react";

import { purpleBase } from "../../../styles/constants";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Link } from "../../Link";
import { BoldText } from "../../Text";

import {
  CheckmarkEmojiDesktop,
  CheckmarkEmojiMobile,
  HeadingContainer,
  TaskActions,
  TaskContentContainer,
  TaskDescription,
  TaskItem,
  TaskItemContainer,
  TaskTitleContainer,
} from "./ChecklistCardStyles";

export interface CheckListTask {
  title: string; // max 105 characters
  description: string | React.ReactNode; // max 116 characters
  ctaText: string;
  ctaAction: () => void;
  isCompleted: boolean;
  estimatedMinutes?: number;
  skipAction?: () => void;
  isPrimaryCTA?: boolean;
}

export const ChecklistCard = ({
  title,
  description,
  estimatedMinutes,
  ctaText,
  ctaAction,
  skipAction,
  isCompleted,
  isPrimaryCTA,
}: CheckListTask) => {
  return (
    <TaskItemContainer data-testid="taskItemContainer">
      <TaskItem $completed={isCompleted}>
        {isCompleted && <CheckmarkEmojiDesktop>✅</CheckmarkEmojiDesktop>}
        <TaskContentContainer $completed={isCompleted}>
          <TaskTitleContainer data-testid="headingContainer">
            <HeadingContainer>
              {isCompleted && (
                <CheckmarkEmojiMobile data-testid="checkmarkEmojiMobile">✅</CheckmarkEmojiMobile>
              )}
              <Heading level={5} color={purpleBase}>
                {title}
              </Heading>
            </HeadingContainer>
            {estimatedMinutes && <BoldText>(est. {estimatedMinutes} min)</BoldText>}
          </TaskTitleContainer>
          <TaskDescription data-testid="taskDescription">{description}</TaskDescription>
        </TaskContentContainer>
        <TaskActions data-testid="taskActions">
          {skipAction && (
            <Link onClick={skipAction} disabled={isCompleted} linkSize="small">
              Skip for now
            </Link>
          )}
          <Button
            title={ctaText}
            buttonType={isPrimaryCTA ? "primary" : "secondary"}
            onClick={ctaAction}
            disabled={isCompleted}
          />
        </TaskActions>
      </TaskItem>
    </TaskItemContainer>
  );
};
