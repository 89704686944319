import { MeetYourRep, VetPreferences, WhyAreYouOnRoo } from "../../../api/vet";
import { RegistrationEducationFormType } from "../RegistrationEducation/RegistrationEducationFormContext";
import { RegistrationLandingFormType } from "../RegistrationLanding/RegistrationLandingFormContext";
import { RegistrationLicenseFormType } from "../RegistrationLicense/RegistrationLicenseFormContext";
import { RegistrationLocationFormType } from "../RegistrationLocation/RegistrationLocationFormContext";
import { RegistrationReferralFormType } from "../RegistrationReferral/RegistrationReferralFormContext";
import { RegistrationSignUpFormType } from "../RegistrationSignUp/RegistrationSignUpFormContext";

import { useRegistrationContext } from "./registrationContext";

export const useRegistrationLandingForm = (): RegistrationLandingFormType => {
  const {
    registrationContext: { firstName, lastName, phone, userType },
  } = useRegistrationContext();
  return { firstName, lastName, userType, phone };
};

export const useRegistrationSignUpForm = (): RegistrationSignUpFormType => {
  const {
    registrationContext: { email, phone, password },
  } = useRegistrationContext();
  return { email, phone, password };
};

export const useRegistrationLocationForm = (): RegistrationLocationFormType => {
  const {
    registrationContext: { travelDistance, zipcode },
  } = useRegistrationContext();
  return { travelDistance, zipcode };
};

export const useRegistrationLicenseForm = (): RegistrationLicenseFormType => {
  const {
    registrationContext: {
      licenses,
      deletedLicenses,
      isCertified,
      isLicensed,
      isRegistered,
      isVTS,
    },
  } = useRegistrationContext();
  return { licenses, deletedLicenses, isCertified, isLicensed, isRegistered, isVTS };
};

export const useRegistrationVetPreferencesForm = (): VetPreferences => {
  const {
    registrationContext: {
      yearsOfPractice,
      preferredAreasOfPractice,
      preferredProcedures,
      typicalAvailableDays,
      lookingToStart,
      currentSituation,
      typicalAvailableDaysOpenResponse,
      lookingToStartOpenResponse,
    },
  } = useRegistrationContext();
  return {
    yearsOfPractice,
    preferredAreasOfPractice,
    preferredProcedures,
    typicalAvailableDays,
    lookingToStart,
    currentSituation,
    typicalAvailableDaysOpenResponse,
    lookingToStartOpenResponse,
  };
};

export const useRegistrationEducationForm = (): RegistrationEducationFormType => {
  const {
    registrationContext: { school, yearsOfPractice },
  } = useRegistrationContext();
  return { school, yearsOfPractice };
};

export const useRegistrationReferralForm = (): RegistrationReferralFormType => {
  const {
    registrationContext: { hearAboutRoo, referralCode },
  } = useRegistrationContext();
  return { hearAboutRoo, referralCode };
};

export const useRegistrationWhyAreYouOnRooForm = (): WhyAreYouOnRoo => {
  const {
    registrationContext: { reasonForRegistering },
  } = useRegistrationContext();
  return { reasonForRegistering };
};

export const useRegistrationMeetYourRepForm = (): MeetYourRep => {
  const {
    registrationContext: { topicsToDiscuss, topicsToDiscussOpenResponse },
  } = useRegistrationContext();
  return { topicsToDiscuss, topicsToDiscussOpenResponse };
};
