import React from "react";
import styled from "styled-components";
import {
  BodyText,
  Container,
  Divider,
  FlexLayout,
  Heading,
  grayBase,
  spacingM,
  spacingXL,
} from "@RooUI";

import { useRooFeatureFlagVariantKey } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";
import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import { FEATURE_FLAGS, FEATURE_FLAG_VARIANTS } from "@Roo/constants/postHogFeatureFlags";
import { formatPrice } from "@Roo/helpers/textUtility";
import { useProgressPercentEffect } from "@Roo/RegistrationNew/Components/Common/hooks/useProgressPercentEffect";
import { useRegistrationMeetYourRepForm } from "@Roo/RegistrationNew/Components/Common/registrationState";
import { REGISTRATION_PROGRESS_PERCENT } from "@Roo/RegistrationNew/Components/Common/service";
import { MeetYourRep } from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/MeetYourRep";
import { CardContent } from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/SegmentedRegistration/CardContent";
import {
  RegistrationMeetYourRepForm,
  RegistrationMeetYourRepFormButton,
} from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/SegmentedRegistration/RegistrationMeetYourRepForm";
import {
  useSave,
  useValidate,
} from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/SegmentedRegistration/service";
import { FormProvider } from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/SegmentedRegistration/RegistrationMeetYourRepFormContext";

import { RooRep } from "./RooRep";

export const CALENDLY_CALL_PROMO_AMOUNT = 30;

interface HighCommitmentMeetYourRepProps {
  isCalendlyCallPromoEnabled?: boolean;
  handleScheduleCall: () => void;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  photo: string | null | undefined;
}
export const HighCommitmentMeetYourRep = ({
  isCalendlyCallPromoEnabled,
  handleScheduleCall,
  firstName,
  lastName,
  photo,
}: HighCommitmentMeetYourRepProps) => {
  const highCommitmentRegistrationVariant = useRooFeatureFlagVariantKey(
    FEATURE_FLAGS.HIGH_COMMITMENT_REGISTRATION
  );
  const isTestVariant = highCommitmentRegistrationVariant === FEATURE_FLAG_VARIANTS.TEST;
  const formattedPromoAmount = formatPrice(CALENDLY_CALL_PROMO_AMOUNT);

  const { t } = useRooTranslation();

  const registrationMeetYourRepForm = useRegistrationMeetYourRepForm();
  const { save } = useSave();
  const { validate } = useValidate();
  useProgressPercentEffect(REGISTRATION_PROGRESS_PERCENT.MeetRooRep);

  const bdName = firstName && lastName ? `${firstName} ${lastName}` : "your local Roo Rep";

  return isTestVariant ? (
    <FormProvider defaultForm={registrationMeetYourRepForm} save={save} validate={validate}>
      <Container>
        {/* Header section */}
        <FlexLayout vertical gap="xl" style={{ marginBottom: spacingXL }}>
          <Heading level={2} color={grayBase}>
            {isCalendlyCallPromoEnabled
              ? t("registration.hcMeetYourRooRep.promo.title", {
                  promoAmount: formattedPromoAmount,
                })
              : t("registration.hcMeetYourRooRep.title")}
          </Heading>
          <SubHeading>
            {t("registration.hcMeetYourRooRep.subtitle", {
              bdName,
            })}
          </SubHeading>
        </FlexLayout>

        {/* Middle section */}
        <CardContent highCommitment />
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
        <RegistrationMeetYourRepForm highCommitment />
      </Container>
      <RooRep highCommitment={true} photo={photo} />
      <RegistrationMeetYourRepFormButton highCommitment={true} />
    </FormProvider>
  ) : (
    // If they are part of the control group, show them old experience
    <MeetYourRep
      handleScheduleCall={handleScheduleCall}
      isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled}
    />
  );
};

const SubHeading = styled.div`
  ${BodyText};
`;

const DividerWrapper = styled.div`
  margin: ${spacingM} 0;
`;
