import { InputNumberProps } from "antd";
import React from "react";

import { FormLabel } from "../../FormLabel";
import { ErrorText } from "../../Text/ErrorText";
import { InputContainer } from "../AntInputStyles";

import { CustomInputNumber } from "./AntInputNumberStyles";

export interface AntInputNumberProps extends InputNumberProps {
  error?: string;
  label?: React.ReactNode;
  isHideErrorText?: boolean;
}
export const InputNumber: React.FC<AntInputNumberProps> = (props) => {
  const { error, label, isHideErrorText, disabled } = props;

  return (
    <InputContainer>
      <FormLabel error={!!error} $disabled={disabled}>
        {label}
      </FormLabel>
      <CustomInputNumber
        {...props}
        controls={false}
        onWheel={(e) => e.currentTarget.blur()}
        status={error ? "error" : ""}
      />
      {error && !isHideErrorText && <ErrorText>{error}</ErrorText>}
    </InputContainer>
  );
};
