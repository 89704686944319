import styled from "styled-components";

import {
  BodyText,
  grayBackground,
  grayWhite,
  mediumMediaBreakpoint,
  purpleBackground,
  purpleBase,
  smallMediaBreakpoint,
  spacingM,
  spacingS,
  spacingXS,
  spacingXXL,
} from "../../../styles/constants";

export const TaskItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${purpleBackground};
  border-bottom: 1px solid ${grayBackground};
  height: 100px;

  @media (max-width: ${mediumMediaBreakpoint}) {
    height: auto;
  }
`;

export const TaskItem = styled.div<{ $completed: boolean }>`
  display: flex;
  align-items: center;
  padding: ${spacingXXL} 32px;
  gap: ${spacingM};
  background-color: ${grayWhite};
  opacity: ${({ $completed }) => ($completed ? 0.7 : 1)};
  overflow-y: hidden;

  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TaskDescription = styled.div`
  ${BodyText}
`;

export const TaskContentContainer = styled.div<{ $completed: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  p {
    display: inline;
    font-weight: 700;
    color: ${purpleBase};
  }

  h5,
  p,
  ${TaskDescription} {
    text-decoration: ${({ $completed }) => ($completed ? "line-through" : "none")};
  }
`;

export const TaskTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingXS};

  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${spacingXS};
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: max-content;
  white-space: nowrap;
  gap: ${spacingS};

  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column-reverse;
    margin-top: ${spacingXS};
    width: 100%;
    gap: ${spacingM};
  }
`;

const CheckmarkEmojiBase = styled.span`
  font-size: 24px;
  line-height: 32px;
  margin-right: ${spacingS};
`;

export const CheckmarkEmojiDesktop = styled(CheckmarkEmojiBase)`
  @media (max-width: ${smallMediaBreakpoint}) {
    display: none;
  }
`;

export const CheckmarkEmojiMobile = styled(CheckmarkEmojiBase)`
  display: none;
  @media (max-width: ${smallMediaBreakpoint}) {
    display: inline;
  }
`;
