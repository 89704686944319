import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { showToast } from "../../../Common/Toasts/action/ToastActions";
import { APIResponse } from "../../../api/common/response";
import { post, rooFetch } from "../../../api/common/rooFetch";
import * as enterpriseActionTypes from "../../../constants/enterpriseConstants";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import * as loaderActions from "../../../constants/loader";
import {
  CreateEnterpriseAction,
  DeleteEnterpriseAction,
  DeleteEnterpriseLogoAction,
  RejectHospitalForEnterpriseAction,
  SaveEnterpriseLeadAction,
  SaveImportedHospitalsAction,
  UpdateInternalReliefStatusAction,
  UploadEnterpriseLogoAction,
} from "../actions/EnterpriseActions";
import {
  Enterprise,
  EnterpriseDetails,
  EnterpriseHospital,
  HospitalGroupDetails,
  PendingEnterprise,
} from "../types";

export function* getEnterpriseListAsync(action: {
  type: typeof enterpriseActionTypes.GET_ENTERPRISE_LIST;
  payload: { isGetListOnly?: boolean };
}) {
  try {
    const apiEndpoint = action?.payload?.isGetListOnly
      ? "api/enterprise/users/enterprises?isGetListOnly=1"
      : "api/enterprise/users/enterprises";
    const response: Response = yield call(rooFetch, window.RooConfig.API_URL + apiEndpoint);
    const responseData: APIResponse<Enterprise[]> = yield response.json();

    if (responseData.status_code === 200) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: enterpriseActionTypes.GET_ENTERPRISE_LIST_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: enterpriseActionTypes.GET_ENTERPRISE_LIST_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting enterprise list" } });
  }
}

export function* getPendingHospitalsForEnterpriseAsync() {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/pendingHospitalsForEnterprise"
    );

    const responseData: APIResponse<PendingEnterprise[]> = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: enterpriseActionTypes.GET_PENDING_HOSPITALS_FOR_ENTERPRISE_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: enterpriseActionTypes.GET_PENDING_HOSPITALS_FOR_ENTERPRISE_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting pending hospitals" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: enterpriseActionTypes.GET_PENDING_HOSPITALS_FOR_ENTERPRISE_FAIL,
      payload: { message: "Error while fetching pending hospitals" },
    });
  }
}

export function* rejectHospitalForEnterpriseAsync(action: RejectHospitalForEnterpriseAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/rejectHospitalForEnterprise",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData: APIResponse<{}> = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: enterpriseActionTypes.REJECT_HOSPITAL_FOR_ENTERPRISE_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: enterpriseActionTypes.REJECT_HOSPITAL_FOR_ENTERPRISE_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while rejecting hospital" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: enterpriseActionTypes.REJECT_HOSPITAL_FOR_ENTERPRISE_FAIL,
      payload: { message: EXCEPTION_MESSAGE },
    });
  }
}

export function* getEnterpriseDetailsAsync(action: {
  type: typeof enterpriseActionTypes.GET_ENTERPRISE_DETAILS;
  payload: { selectedEnterpriseId: number; isSkipAuthentication?: boolean };
}) {
  try {
    const { selectedEnterpriseId } = action.payload;
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/users/enterprises/${selectedEnterpriseId}`
    );

    const responseData: APIResponse<{
      enterpriseDetails: EnterpriseDetails;
      enterpriseHospitals: EnterpriseHospital[];
      groupDetails: HospitalGroupDetails[];
    }> = yield response.json();

    if (responseData.status_code === 200) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: enterpriseActionTypes.GET_ENTERPRISE_DETAILS_SUCCESS,
        payload: responseData.data as {
          enterpriseDetails: EnterpriseDetails;
          enterpriseHospitals: EnterpriseHospital[];
          groupDetails: HospitalGroupDetails[];
        },
      });
    } else {
      yield put({
        type: enterpriseActionTypes.GET_ENTERPRISE_DETAILS_FAIL,
        payload: {
          status: responseData.status_code,
          message: responseData.message || EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting enterprise details" },
    });
  }
}

export function* saveEnterpriseAsync(action: CreateEnterpriseAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/users/createEnterprise",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<{
      data: {
        errorMessageSaveEnterpriseLead?: string;
        enterpriseId?: number;
        enterpriseLeadUserId?: number;
      };
    }> = yield response.json();

    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessageSaveEnterpriseLead } = data.data.data;
      if (errorMessageSaveEnterpriseLead) {
        yield put({ type: enterpriseActionTypes.GET_ENTERPRISE_LIST });
        yield put({
          type: enterpriseActionTypes.SAVE_ENTERPRISE_LEAD_FAIL,
          payload: { errorMessage: errorMessageSaveEnterpriseLead },
        });
      } else {
        yield put({ type: enterpriseActionTypes.GET_ENTERPRISE_LIST });
        yield put({
          type: enterpriseActionTypes.CREATE_ENTERPRISE_SUCCESS,
          payload: {
            newEnterpriseName: action.payload.enterpriseName,
            newEnterpriseId: data.data.data.enterpriseId,
            newEnterpriseLeadUserId: data.data.data.enterpriseLeadUserId,
          },
        });
      }
    } else {
      yield put({
        type: enterpriseActionTypes.CREATE_ENTERPRISE_FAIL,
        payload: "Failed to createEnterprise.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while creating enterprise" } });
    yield put({
      type: enterpriseActionTypes.CREATE_ENTERPRISE_FAIL,
      payload: "Failed to createEnterprise.",
    });
  }
}

export function* saveImportedHospitalsAsync(action: SaveImportedHospitalsAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/users/saveImportedHospitals",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<{ errorMessage?: string }> = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: enterpriseActionTypes.SAVE_IMPORTED_HOSPITALS_FAIL,
          payload: errorMessage,
        });
      } else {
        yield put({
          type: enterpriseActionTypes.SAVE_IMPORTED_HOSPITALS_SUCCESS,
        });
      }
    } else {
      yield put({
        type: enterpriseActionTypes.SAVE_IMPORTED_HOSPITALS_FAIL,
        payload: "Failed to saveImportedHospitals.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving imported hospitals" } });
    yield put({
      type: enterpriseActionTypes.SAVE_IMPORTED_HOSPITALS_FAIL,
      payload: "Failed to saveImportedHospitals.",
    });
  }
}

export function* saveEnterpriseLeadAsync(action: SaveEnterpriseLeadAction) {
  try {
    const isUpdate = action.payload.isUpdate;
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/saveEnterpriseLead",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<{ errorMessage?: string }> = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data.status_code === 200) {
      const { errorMessage } = data.data;
      if (errorMessage) {
        yield put({
          type: enterpriseActionTypes.SAVE_ENTERPRISE_LEAD_FAIL,
          payload: { errorMessage },
        });
      } else {
        yield put({ type: enterpriseActionTypes.GET_ENTERPRISE_LIST });
        yield put({
          type: enterpriseActionTypes.SAVE_ENTERPRISE_LEAD_SUCCESS,
        });
        yield put(
          showToast({
            variant: "success",
            message: "Success!",
            description: `Enterprise lead successfully ${isUpdate ? "updated" : "added"}.`,
          })
        );
      }
    } else {
      yield put({
        type: enterpriseActionTypes.SAVE_ENTERPRISE_LEAD_FAIL,
        payload: "Failed to saveEnterpriseLead.",
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while saving enterprise lead" } });
    yield put({
      type: enterpriseActionTypes.SAVE_ENTERPRISE_LEAD_FAIL,
      payload: "Failed to saveEnterpriseLead.",
    });
  }
}

function* deleteEnterpriseAsync(action: DeleteEnterpriseAction) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/deleteEnterprise`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<{}> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: enterpriseActionTypes.GET_ENTERPRISE_LIST,
      });
      yield put({
        type: enterpriseActionTypes.DELETE_ENTERPRISE_SUCCESS,
      });
    } else if (data.status_code === 400) {
      yield put({ type: loaderActions.UNSET_LOADER });
      yield put({
        type: enterpriseActionTypes.DELETE_ENTERPRISE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting enterprise" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: enterpriseActionTypes.DELETE_ENTERPRISE_FAIL,
      payload: { status: error.status_code, message: error.message || EXCEPTION_MESSAGE },
    });
  }
}

function* updateInternalReliefStatusAsync(action: UpdateInternalReliefStatusAction) {
  try {
    yield put({ type: loaderActions.SET_LOADER });
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/updateInternalReliefStatus`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data: APIResponse<{}> = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: enterpriseActionTypes.UPDATE_INTERNAL_RELIEF_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: enterpriseActionTypes.UPDATE_INTERNAL_RELIEF_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating internal relief status" },
    });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: enterpriseActionTypes.UPDATE_INTERNAL_RELIEF_FAIL,
      payload: { status: error.status_code, message: error.message || EXCEPTION_MESSAGE },
    });
  }
}

export function* uploadLogoAsync(action: UploadEnterpriseLogoAction) {
  const formData = new FormData();

  for (const element of action.payload.files) {
    formData.append("document", element);
    formData.append("enterpriseId", action.payload.enterpriseId.toString());
    formData.append("uploadFileType", "enterpriseLogo");
  }

  try {
    const response: APIResponse<{ status_code?: number; data?: { imagePath?: string } }> =
      yield call(post, "api/user/uploadDocument", formData);

    yield put({ type: loaderActions.UNSET_LOADER });

    if (response.status_code === 200) {
      yield put({
        type: enterpriseActionTypes.UPDATE_ENTERPRISE_LOGO_SUCCESS,
        payload: { logoPath: response.data?.data?.imagePath },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while uploading logo" } });

    yield put({
      type: enterpriseActionTypes.UPDATE_ENTERPRISE_LOGO_FAIL,
      payload: { status: error.status, message: error.message || EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteLogoAsync(action: DeleteEnterpriseLogoAction) {
  const { enterpriseId } = action.payload;
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/deleteLogo",
      {
        method: "DELETE",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<{}> = yield response.json();
    yield put({ type: loaderActions.UNSET_LOADER });
    if (data?.status_code === 200) {
      yield put({
        type: enterpriseActionTypes.DELETE_ENTERPRISE_HOSPITAL_SUCCESS,
        payload: { enterpriseId },
      });
    } else {
      yield put({
        type: enterpriseActionTypes.DELETE_ENTERPRISE_LOGO_FAIL,
        payload: { status: data?.status_code, data: data?.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting logo" } });
    yield put({ type: loaderActions.UNSET_LOADER });
    yield put({
      type: enterpriseActionTypes.DELETE_ENTERPRISE_LOGO_FAIL,
      payload: { status: error.status_code, data: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootAdminEnterpriseSaga() {
  yield takeEvery(enterpriseActionTypes.GET_ENTERPRISE_LIST, getEnterpriseListAsync);
  yield takeEvery(
    enterpriseActionTypes.GET_PENDING_HOSPITALS_FOR_ENTERPRISE,
    getPendingHospitalsForEnterpriseAsync
  );
  yield takeEvery(
    enterpriseActionTypes.REJECT_HOSPITAL_FOR_ENTERPRISE,
    rejectHospitalForEnterpriseAsync
  );
  yield takeEvery(enterpriseActionTypes.GET_ENTERPRISE_DETAILS, getEnterpriseDetailsAsync);
  yield takeEvery(enterpriseActionTypes.CREATE_ENTERPRISE, saveEnterpriseAsync);
  yield takeEvery(enterpriseActionTypes.SAVE_ENTERPRISE_LEAD, saveEnterpriseLeadAsync);
  yield takeEvery(enterpriseActionTypes.SAVE_IMPORTED_HOSPITALS, saveImportedHospitalsAsync);
  yield takeEvery(enterpriseActionTypes.DELETE_ENTERPRISE, deleteEnterpriseAsync);
  yield takeEvery(
    enterpriseActionTypes.UPDATE_INTERNAL_RELIEF_REQUEST,
    updateInternalReliefStatusAsync
  );
  yield takeEvery(enterpriseActionTypes.UPDATE_ENTERPRISE_LOGO_REQUEST, uploadLogoAsync);
  yield takeEvery(enterpriseActionTypes.DELETE_ENTERPRISE_LOGO_REQUEST, deleteLogoAsync);
}
