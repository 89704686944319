import * as Sentry from "@sentry/react";

import { post } from "./common/rooFetch";

export interface HireRequestPayload {
  hospitalId: number;
  providerId: number;
  providerName: string;
  providerType: "vet" | "tech";
  hasDiscussedOpportunity?: boolean;
  intendedStartDate?: string;
  hireNote?: string;
}

export const submitHireRequest = async (
  payload: HireRequestPayload
): Promise<{ success: boolean; data?: { requestSubmittedOn: Date | null } }> => {
  try {
    return await post<
      HireRequestPayload,
      { success: boolean; data?: { requestSubmittedOn: Date | null } }
    >("api/hospital/hireRequest", payload);
  } catch (error) {
    Sentry.captureException(new Error("Error submitting hire request"), {
      extra: { error, payload },
    });
    throw error;
  }
};
