import { BodyText, FlexLayout, Icon, greenBase } from "@RooUI";
import React from "react";
import styled from "styled-components";

import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";

interface CardContentProps {
  highCommitment?: boolean;
}
export const CardContent = ({ highCommitment }: CardContentProps) => {
  const { t } = useRooTranslation();
  const prefix = highCommitment ? "hc" : "lc";

  const section1Text = t(`registration.${prefix}MeetYourRooRep.section1.text`);
  const section2Text = t(`registration.${prefix}MeetYourRooRep.section2.text`);
  const section3Text = t(`registration.${prefix}MeetYourRooRep.section3.text`);

  return (
    <FlexLayout vertical gap="xxl">
      <FlexLayout vertical gap="l">
        <FlexLayout align="start" gap="m">
          <Icon name="Check" color={greenBase} />
          <FlexLayout vertical gap="xs">
            <Text>{section1Text}</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout align="start" gap="m">
          <Icon name="Check" color={greenBase} />
          <FlexLayout vertical gap="xs">
            <Text>{section2Text}</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout align="start" gap="m">
          <Icon name="Check" color={greenBase} />
          <FlexLayout vertical gap="xs">
            <Text>{section3Text}</Text>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

const Text = styled.div`
  ${BodyText}
`;
