import { extractUrlInfo } from "./urlUtils";

export const TRACKING_PARAMETER_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "ad_set_id",
  "ad_group_id",
  "ad_id",
] as const;

export const TRACKED_REFERRERS_KEY = "tracked_utm_referrers";

type TrackingParams = ReturnType<typeof getTrackingParams>;

export const getTrackingParams = (queryString: string) => {
  const currentTrackingParams = getQueryParams(queryString, TRACKING_PARAMETER_KEYS);
  return {
    utmCampaign: currentTrackingParams.utm_campaign || undefined,
    utmContent: currentTrackingParams.utm_content || undefined,
    utmMedium: currentTrackingParams.utm_medium || undefined,
    utmSource: currentTrackingParams.utm_source || undefined,
    utmTerm: currentTrackingParams.utm_term || undefined,
    adSetId: currentTrackingParams.ad_set_id || undefined,
    adGroupId: currentTrackingParams.ad_group_id || undefined,
    adId: currentTrackingParams.ad_id || undefined,
  };
};

export const getReferralCode = (queryString: string) => {
  return getQueryParams(queryString, ["referralCode"]);
};

export const getQueryParams = <T extends string>(
  queryString: string,
  keys: ReadonlyArray<T>
): Record<T, string | null> => {
  const params = new URLSearchParams(queryString);
  const queryParams = {} as Record<T, string | null>;
  keys.forEach((key) => {
    queryParams[key] = params.get(key);
  });
  return queryParams;
};

function getReferrerParams(
  referrer: string,
  options?: { checkTrackedReferrers?: boolean }
): TrackingParams {
  if (!referrer) return getTrackingParams("");

  try {
    const referrerUrl = new URL(referrer);
    const isValidHostname = extractUrlInfo(referrer)?.hostname === window.location.hostname;

    if (!isValidHostname) return getTrackingParams("");

    if (options?.checkTrackedReferrers) {
      const trackedReferrers = JSON.parse(sessionStorage.getItem(TRACKED_REFERRERS_KEY) || "[]");
      if (trackedReferrers.includes(referrer)) return getTrackingParams("");
    }

    const params = getTrackingParams(referrerUrl.search);

    if (options?.checkTrackedReferrers && Object.values(params).some((param) => !!param)) {
      const trackedReferrers = JSON.parse(sessionStorage.getItem(TRACKED_REFERRERS_KEY) || "[]");
      trackedReferrers.push(referrer);
      sessionStorage.setItem(TRACKED_REFERRERS_KEY, JSON.stringify(trackedReferrers));
    }

    return params;
  } catch {
    return getTrackingParams("");
  }
}

function combineParams(
  currentParams: TrackingParams,
  referrerParams: TrackingParams
): { params: Partial<Omit<TrackingParams, "adId">>; url: string } {
  const hasCurrentParams = Object.values(currentParams).some((value) => !!value);

  const { adId: _, ...paramsWithoutAdId } = hasCurrentParams ? currentParams : referrerParams;

  return {
    params: paramsWithoutAdId,
    url: hasCurrentParams ? window.location.href : document.referrer,
  };
}

export function getUtmParamsWithReferrerFallback() {
  const currentParams = getTrackingParams(window.location.search);
  const referrerParams = getReferrerParams(document.referrer);
  return combineParams(currentParams, referrerParams).params;
}

export function getUtmParamsWithOneTimeReferrer() {
  const currentParams = getTrackingParams(window.location.search);
  const referrerParams = getReferrerParams(document.referrer, { checkTrackedReferrers: true });
  return combineParams(currentParams, referrerParams);
}

export const getIsFromEvent = (queryString: string) => {
  const { fromEvent } = getQueryParams(queryString, ["fromEvent"]);
  return fromEvent === "1";
};

export const buildQuery = (url: string, parameters: Record<string, unknown>) => {
  const queryParams = Object.entries(parameters).reduce((acc, [key, value]) => {
    if (value) acc[key] = value;
    return acc;
  }, {} as Record<string, unknown>);

  const paramString = new URLSearchParams(queryParams as Record<string, string>).toString();
  return `${url}?${paramString}`;
};
