import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { useActiveFeatureFlags } from "posthog-js/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { z } from "zod";

const SESSION_STORAGE_KEY = "EarlyAccessFeatures";

const earlyAccessFeatures = ["oct-2024-new-find-shifts-early-access"] as const;

const earlyAccessFeaturesSchema = z.array(z.enum(earlyAccessFeatures));

export type EarlyAccessFeature = (typeof earlyAccessFeatures)[number];

export type EarlyAccessFeatureOptions = {
  storage?: "posthog" | "sessionStorage";
};

export const enableEarlyAccessFeature = (
  key: EarlyAccessFeature,
  { storage = "posthog" }: EarlyAccessFeatureOptions = {}
) => {
  if (storage === "posthog") {
    posthog.updateEarlyAccessFeatureEnrollment(key, true);
  } else {
    const features = getSessionStorageFeatures();

    if (!features.includes(key)) {
      features.push(key);
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(features));
    }
  }
};

export const disableEarlyAccessFeature = (
  key: EarlyAccessFeature,
  { storage = "posthog" }: EarlyAccessFeatureOptions = {}
) => {
  if (storage === "posthog") {
    posthog.updateEarlyAccessFeatureEnrollment(key, false);
  } else {
    const features = getSessionStorageFeatures().filter((feature) => feature !== key);
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(features));
  }
};

const getSessionStorageFeatures = () => {
  try {
    const payload = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) || "[]");
    const result = earlyAccessFeaturesSchema.safeParse(payload);

    if (!result.success) {
      Sentry.captureMessage("Invalid session storage for early access features", {
        extra: { context: "getSessionStorageFeatures" },
      });

      return [];
    }

    return result.data;
  } catch (error) {
    Sentry.captureMessage("Invalid session storage for early access features", {
      extra: { context: "getSessionStorageFeatures" },
    });

    return [];
  }
};

const getEarlyAccessFeatures = async (
  activeFeatureFlags: string[],
  { storage = "posthog" }: EarlyAccessFeatureOptions = {}
) => {
  if (storage === "posthog") {
    return new Promise<EarlyAccessFeature[]>((resolve) => {
      posthog.getEarlyAccessFeatures((features) => {
        const flagKeys = features
          .map(({ flagKey }) => flagKey)
          .filter(
            (flagKey): flagKey is EarlyAccessFeature =>
              flagKey !== null &&
              earlyAccessFeatures.includes(flagKey as EarlyAccessFeature) &&
              activeFeatureFlags.includes(flagKey)
          );

        resolve(flagKeys);
      }, true);
    });
  } else {
    return Promise.resolve(getSessionStorageFeatures());
  }
};

export const useRooEarlyAccessFeatures = (options: EarlyAccessFeatureOptions) => {
  const activeFlags = useActiveFeatureFlags();
  const [features, setFeatures] = useState<Set<EarlyAccessFeature>>(new Set());
  const [isLoading, setIsLoading] = useState(true);

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isTimedOut = useRef(false);

  const optionsDependencyKey = JSON.stringify(options);

  const updateFeature = useCallback(
    async (key: EarlyAccessFeature, value: boolean) => {
      if (value) {
        await enableEarlyAccessFeature(key, options);
      } else {
        await disableEarlyAccessFeature(key, options);
      }

      setFeatures((previous) => {
        const newFeatures = new Set(previous);

        if (value) {
          newFeatures.add(key);
        } else {
          newFeatures.delete(key);
        }

        return newFeatures;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsDependencyKey]
  );

  const enableFeature = useCallback(
    (key: EarlyAccessFeature) => updateFeature(key, true),
    [updateFeature]
  );

  const disableFeature = useCallback(
    (key: EarlyAccessFeature) => updateFeature(key, false),
    [updateFeature]
  );

  useEffect(() => {
    timeout.current = setTimeout(() => {
      isTimedOut.current = true;
      setIsLoading(false);
    }, 5000);
  }, []);

  // Posthog loads `activeFlags` asynchronously, so we need to wait for them to
  // be available. They transition from undefined to an array of strings when
  // they are loaded.
  // An "early access feature" is valid if it's underlying feature flag is
  // active for the current user.
  useEffect(() => {
    if (typeof activeFlags !== "undefined") {
      setIsLoading(true);

      getEarlyAccessFeatures(activeFlags, options)
        .then((flagKeys) => {
          if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
          }

          if (!isTimedOut.current) {
            setFeatures(new Set(flagKeys));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Sentry.captureException(error, { extra: { context: "getEarlyAccessFeatures" } });
        });
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFlags, optionsDependencyKey]);

  return { isLoading, features, enableFeature, disableFeature };
};
