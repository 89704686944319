import React, { ReactNode, useEffect, useState } from "react";

import { blueDark600, disabledTextColor, redBase } from "../../styles/constants";
import { Icon, IconProps } from "../Icon";
import { OpenText } from "../Input/OpenText";
import { ErrorText, Text } from "../Text";

import {
  Control,
  CustomCheckLabel,
  IconLabel,
  InformationalDiv,
  Input,
  InputContainer,
} from "./CustomCheckboxStyles";

export interface CustomCheckboxProps {
  id?: string | number;
  label?: string | ReactNode;
  name?: string;
  value?: string | number;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  type?: string;
  icon?: IconProps["name"];
  size?: "small" | "large";
  className?: string;
  style?: React.CSSProperties;
  onChange?: (event: any, value?: any) => void;
  error?: string;
  "data-testid"?: string;
  hideErrorLabel?: boolean; // used with the checkbox group
  input?: {
    onChange: (value: any) => void;
    value: any;
  };
  topAlignedLabel?: boolean;
}

export const Checkbox = <NarrowCheckboxProps extends CustomCheckboxProps>(
  props: NarrowCheckboxProps
) => {
  const {
    type = "classic",
    id,
    label = "",
    disabled = false,
    name = "",
    value = "",
    defaultChecked = false,
    checked,
    icon,
    size = "large",
    className = "",
    style,
    error,
    onChange,
    input,
    hideErrorLabel,
    topAlignedLabel = false,
  } = props;

  const [infoCheckboxChecked, setInfoCheckboxChecked] = useState(checked);

  useEffect(() => {
    if (type === "informational") {
      setInfoCheckboxChecked(checked);
    }
  }, [checked, type]);

  const handleCbChange = (e: any) => {
    if (disabled) {
      return;
    }
    if (type === "informational") {
      const newCheckedState = !infoCheckboxChecked;
      setInfoCheckboxChecked(newCheckedState);
      e.target.checked = newCheckedState;
      e.target.id = String(id);
    }
    if (onChange) {
      onChange(e, value);
    }
  };

  let checkboxIconColor = blueDark600;
  if (disabled) {
    checkboxIconColor = disabledTextColor;
  } else if (error) {
    checkboxIconColor = redBase;
  } else if (checked) {
    checkboxIconColor = blueDark600;
  }

  return type === "classic" ? (
    <div>
      <CustomCheckLabel
        $disabled={disabled}
        $error={!!error}
        $topAlignedLabel={topAlignedLabel}
        className={className}
        style={style}
      >
        <>
          <InputContainer data-testid={props["data-testid"]}>
            <Input
              type="checkbox"
              id={id ? String(id) : undefined}
              name={name}
              {...(checked !== undefined ? { checked } : { defaultChecked })}
              value={value}
              disabled={disabled}
              onChange={handleCbChange}
            />
            <Control $error={!!error} $topAlignedLabel={topAlignedLabel} />
          </InputContainer>
          <Text disabled={disabled} error={!!error ? true : undefined}>
            {label}
          </Text>
          {input ? (
            <OpenText
              disabled={!checked}
              value={input.value}
              onChange={(e) => input.onChange(e.target.value)}
            />
          ) : null}
        </>
      </CustomCheckLabel>
      {error && !hideErrorLabel && <ErrorText>{error}</ErrorText>}
    </div>
  ) : (
    <InformationalDiv
      $size={size}
      $disabled={disabled}
      $error={!!error}
      id={id ? String(id) : undefined}
      className={`${className} ${infoCheckboxChecked ? "checked" : "unchecked"}`}
      style={style}
      onClick={handleCbChange}
      data-testid={name}
    >
      {icon ? <Icon name={icon} color={checkboxIconColor} /> : null}
      <IconLabel>{label}</IconLabel>
    </InformationalDiv>
  );
};
