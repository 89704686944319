import { BodyText, Button, FlexLayout, Heading, Link, Modal, grayBase, spacingXL } from "@RooUI";
import React, { useState } from "react";
import styled from "styled-components";

import { MeetYourRooRep } from "@Roo/Common/MeetYourRooRep/MeetYourRooRep";
import { useRooTranslation } from "@Roo/Common/Wrappers/useRooTranslation";
import { isMobileDevice } from "@Roo/helpers/jsUtility";
import { formatPrice, getCopyrightYear } from "@Roo/helpers/textUtility";

import { useRegistrationContext } from "../Common/registrationContext";

export const CALENDLY_CALL_PROMO_AMOUNT = 30;

interface MeetYourRepProps {
  isCalendlyCallPromoEnabled?: boolean;
  handleScheduleCall: () => void;
}
export const MeetYourRep = ({
  isCalendlyCallPromoEnabled,
  handleScheduleCall,
}: MeetYourRepProps) => {
  const {
    registrationContext: { entityId, email },
  } = useRegistrationContext();
  const isMobile = isMobileDevice();

  // Calendly promo
  const formattedPromoAmount = formatPrice(CALENDLY_CALL_PROMO_AMOUNT);
  const formattedPromoAmountDecimal = formatPrice(CALENDLY_CALL_PROMO_AMOUNT, {
    isForceDecimal: true,
  });
  const { t } = useRooTranslation();

  const [isPromoTermsModalOpen, setIsPromoTermsModalOpen] = useState(false);

  const handlePromoTermsClick = () => {
    setIsPromoTermsModalOpen(true);
  };

  const handleClosePromoTermsModal = () => {
    setIsPromoTermsModalOpen(false);
  };

  return (
    <>
      {/* Header section */}
      <FlexLayout vertical gap="xl" style={{ marginBottom: spacingXL }}>
        <Heading level={3} color={grayBase}>
          {isCalendlyCallPromoEnabled
            ? t("registration.meetYourRooRep.promo.title", { promoAmount: formattedPromoAmount })
            : t("registration.meetYourRooRep.title")}
        </Heading>
        {isCalendlyCallPromoEnabled && (
          <SubHeading>
            {t("registration.meetYourRooRep.promo.subtitle", {
              promoAmount: formattedPromoAmount,
            })}
          </SubHeading>
        )}
      </FlexLayout>

      {/* Middle section */}
      <MeetYourRooRep vetId={entityId} isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled} />

      {/* Action section */}
      <FlexLayout
        gap="m"
        style={{ marginTop: spacingXL }}
        vertical={isMobile}
        align={isMobile ? "center" : undefined}
      >
        <Button
          buttonType="primary"
          title="Schedule Call and View Shifts"
          onClick={handleScheduleCall}
          testId="scheduleActivationBtn"
        />
      </FlexLayout>

      {/* Footer section */}
      {isCalendlyCallPromoEnabled && (
        <span>
          <Link linkSize="small" linkStyle="muted" onClick={handlePromoTermsClick}>
            {t("registration.meetYourRooRep.promo.footer.terms_and_conditions")}
          </Link>
        </span>
      )}

      {/* Promo terms modal section */}
      {isCalendlyCallPromoEnabled && (
        <Modal
          size="m"
          header={t("registration.meetYourRooRep.promo.modal.title")}
          body={t("registration.meetYourRooRep.promo.modal.disclaimer", {
            emailId: email,
            promoAmountDecimal: formattedPromoAmountDecimal,
            copyrightYear: getCopyrightYear(),
          })}
          showModal={isPromoTermsModalOpen}
          onCloseModal={handleClosePromoTermsModal}
          primaryButtonProps={{
            title: t("registration.meetYourRooRep.promo.modal.cta"),
            onClick: handleClosePromoTermsModal,
          }}
        />
      )}
    </>
  );
};

const SubHeading = styled.div`
  ${BodyText};
`;
