import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUtmParamsWithOneTimeReferrer } from "../../Common/queryParamsUtils";
import { RootState } from "../../store";
import { Event, EventTracker } from "../service/EventTracker/EventTrackerService";

export const useSessionTracking = () => {
  const location = useLocation();
  const loggedInUserId = useSelector((state: RootState) => state.login.userId);
  const isFirstMount = useRef(true);
  const lastTrackedUrl = useRef("");

  const trackSession = useCallback((userId: string) => {
    if (!userId) return;

    const { params: utmParams, url } = getUtmParamsWithOneTimeReferrer();
    const hasUtmParams = Object.values(utmParams).some((param) => !!param);

    if (lastTrackedUrl.current === url) return;

    if (hasUtmParams) {
      lastTrackedUrl.current = url;
      EventTracker.send({
        eventName: Event.Name.UTM_VISIT,
        eventType: Event.Type.PAGE_VIEW,
        entityType: Event.Entity.USER,
        entityId: Number(userId),
        context: {
          ...utmParams,
          url,
          isInitialVisit: isFirstMount.current,
        },
      });
    }
  }, []);

  useEffect(() => {
    trackSession(loggedInUserId);
    if (isFirstMount.current) {
      isFirstMount.current = false;
    }
  }, [location.pathname, location.search, loggedInUserId, trackSession]);

  return { trackSession };
};
