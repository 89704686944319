import { Carousel } from "antd";
import { Children } from "react";
import styled from "styled-components";

import { grayBase, spacingS } from "../../styles/constants";
import { smallMediaBreakpoint } from "../../styles/constants/mediaBreakpoints";

export const CarouselStyled = styled(Carousel)<{ $slidesToShow?: number }>`
  padding: ${(props) =>
    props.children && props.$slidesToShow && Children.count(props.children) > props.$slidesToShow
      ? `0 32px 32px`
      : "0 0 32px"};

  > svg:first-of-type {
    position: absolute;
    top: calc(50% - 32px);
    z-index: 1;
    left: 0;
  }
  > svg:last-of-type {
    position: absolute;
    top: calc(50% - 32px);
    right: 0;
    z-index: 1;
  }

  .slick-list {
    position: initial;
    overflow-y: visible;
    overflow-x: clip;
    .slick-slide > div {
      margin-right: ${({ $slidesToShow }) => ($slidesToShow && $slidesToShow > 1 ? spacingS : "0")};
    }
  }

  .slick-dots li button,
  .slick-dots li.slick-active button {
    background: ${grayBase};
  }
  @media (max-width: ${smallMediaBreakpoint}) {
    padding: 0 32px 32px;
  }
`;
