import { FlexLayout, Icon, Modal, Text, yellowBase } from "@RooUI";
import dayjs from "dayjs";
import React from "react";

interface FullTimePlacementDuplicateWarningModalProps {
  showModal: boolean;
  requestSubmittedOn: Date | null;
  isVet: boolean;
  onCloseModal: () => void;
}

export const FullTimePlacementDuplicateWarningModal = ({
  showModal,
  requestSubmittedOn,
  isVet,
  onCloseModal,
}: FullTimePlacementDuplicateWarningModalProps) => {
  const formattedDate = requestSubmittedOn ? dayjs(requestSubmittedOn).format("MMM. D") : "";

  return (
    <Modal
      showModal={showModal}
      onCloseModal={onCloseModal}
      size="m"
      $tabNameArr={[
        {
          index: 1,
          name: "Form Already Submitted!",
          panelContent: (
            <FlexLayout gap="l">
              <div>
                <Icon name="ErrorOutline" color={yellowBase} size="xl" />
              </div>
              <Text>
                A full-time placement form for this {isVet ? "vet" : "tech"} was already submitted
                {formattedDate ? ` on ${formattedDate}` : ""}. Keep an eye on your email—we'll
                follow up with the next steps soon!
              </Text>
            </FlexLayout>
          ),
        },
      ]}
      defaultSelectedTabIndex={1}
      primaryButtonProps={{
        title: "Got It",
        buttonType: "primary",
        size: "small",
        onClick: onCloseModal,
      }}
      centerButtons
    />
  );
};
