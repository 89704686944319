export type SpacingSize = "xxl" | "xl" | "l" | "m" | "s" | "xs";

export type SpacingKey = `spacing${Uppercase<SpacingSize>}`;

export const spacing: Record<SpacingSize, string> = {
  xxl: "24px",
  xl: "20px",
  l: "16px",
  m: "12px",
  s: "8px",
  xs: "4px",
} as const;

// For backward compatibility with existing imports
export const spacingXXL = spacing.xxl;
export const spacingXL = spacing.xl;
export const spacingL = spacing.l;
export const spacingM = spacing.m;
export const spacingS = spacing.s;
export const spacingXS = spacing.xs;
