import { cloneDeep, find } from "lodash";

import { EXCEPTION_MESSAGE } from "../../constants/errorMessage";
import * as C from "../../constants/techConstants";

const initialState = {
  searchShiftResult: [],
  searchCenterLatitude: "",
  searchCenterLongitude: "",
  isCalendarView: false,
  isGridView: false,
  isMapView: false,
  isRecsView: false,
  isSearchShiftSuccess: false,

  linkedShiftData: [],
  linkedShiftFetched: false,

  shiftRequestResponse: {
    isShiftRequest: false,
    shiftGroupId: null,
  },
  isW9Success: false,
  W9ErrorMessage: "",
  signatureId: "",
  techLicenseInformation: [],
  techSpecializationInformation: [],
  check: false,
  isShowFirstTimerModal: false,
  licenseInformationSuccess: false,
  bankingInfoSuccess: false,
  isShowShiftNotAvailableModal: false,
  requestedHospOfShiftNotAvailable: "",
  shiftRequestResponseMessage: "",
  requestedShiftGroupId: null,
  ratingModal: false,
  searchParameters: {},
  sharedSearch: null,
  totalCount: 0,
  techSignNowData: null,
  myShifts: [],
  requestFrom: null,
  isSearchClicked: false,
  isBackgroundSuccess: false,
  backgroundErrorMessage: "",
  nProbationShifts: null,
  errorMessage: "",
  techShiftDetails: null,
  isShowConfirmRequestClosed: false,
  isShowTrainingPopup: false,

  isReloadShifts: false,
  isRemoveConfirmedTechActionCompleted: false,
  reliabilityRating: null,
  techStanding: "",
  isError: false,
  removeConfirmedTechErrorMsg: "",
  isCalculateShiftCostActionCompleted: false,
  isCalculatingShiftPricing: false,
  shiftAmount: 0,
  showICAgreement: false,
  eventListForTech: [],
  isEventListFetched: false,
  isUpdateInfoPayment: null,
  isSkippedW9Modal: null,
  isInitialMapSortComplete: false,
  isShowDeleteShiftRequestPanel: false,
  isShowCancelConfirmedShiftPanel: false,
  isConfirmedShiftCancelledSuccess: false,
  cancelShiftData: {},

  dashboardView: null,
};

// eslint-disable-next-line default-param-last
const techDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.SEARCH_TECH_SHIFT_REQUEST: {
      return {
        ...state,
        isSearchShiftSuccess: false,
      };
    }
    case C.SEARCH_TECH_SHIFT_SUCCESS:
      if (action.payload.searchShiftResult === undefined) {
        return {
          ...state,
          searchShiftResult: [],
          isSearchShiftSuccess: true,
          totalCount: 0,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: "",
          searchCenterLongitude: "",
        };
      } else {
        return {
          ...state,
          searchShiftResult: [...action.payload.searchShiftResult],
          isSearchShiftSuccess: true,
          totalCount: action.payload.totalCount,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: action.payload.latitude,
          searchCenterLongitude: action.payload.longitude,
        };
      }

    case C.INITIALIZE_SEARCH_RESULTS:
    case C.SEARCH_TECH_SHIFT_FAIL:
      return {
        ...state,
        isSearchShiftSuccess: false,
        searchShiftResult: [],
        totalCount: 0,
        requestFrom: null,
      };

    case C.GET_LINKED_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        linkedShiftData: action.payload,
        linkedShiftFetched: true,
      };

    case C.GET_LINKED_TECH_SHIFT_FAIL:
      return {
        ...state,
        linkedShiftFetched: true,
      };

    case C.SET_TECH_DASHBOARD_CALENDAR_VIEW:
      return {
        ...state,
        isCalendarView: true,
        isGridView: false,
        isMapView: false,
      };

    case C.SET_TECH_DASHBOARD_GRID_VIEW:
      return {
        ...state,
        searchShiftResult: [],
        isGridView: true,
        isCalendarView: false,
        isMapView: false,
        requestFrom: null,
      };

    case C.SET_TECH_DASHBOARD_MAP_VIEW:
      return {
        ...state,
        searchShiftResult: [],
        isCalendarView: false,
        isGridView: false,
        isMapView: true,
      };

    case C.TECH_REQUEST_TECH_SHIFT_SUCCESS:
      let shiftData;
      let updateIndex = null;
      const isFromAllDone = action.payload.isFromAllDone;
      const shiftRequestEndTimestamp = action.payload.shiftRequestResponse?.expiresTimeStamp;
      if (state.requestFrom) {
        shiftData = [...action.payload.availableShifts];
      } else if (action.payload.shiftData.length > 0) {
        shiftData = [...action.payload.shiftData];
      } else {
        shiftData = [...state.searchShiftResult];
      }
      shiftData.map((element, index) => {
        if (element.shiftGroupId === action.payload.shiftRequestResponse.shiftGroupId) {
          updateIndex = index;
        }
      });
      const currentTimeStamp = (new Date().getTime() / 1000 + "").split(".")[0];
      const updateSearchShift = [...shiftData];
      const myShifts = cloneDeep([...state.myShifts]);
      if (updateIndex !== null) {
        // if shift requested from All done page and if there is a page refresh,the data may not be in the searchShiftsList so first check updateIndex.
        const oRequestedShift = updateSearchShift[updateIndex];
        oRequestedShift.shiftRequestedOn = currentTimeStamp;
        oRequestedShift.shiftRequestEndTimestamp = shiftRequestEndTimestamp;
        updateSearchShift[updateIndex].shiftRequestedOn = currentTimeStamp;
        updateSearchShift[updateIndex].shiftRequestEndTimestamp = shiftRequestEndTimestamp;
        myShifts.push({
          ...oRequestedShift,
          techShiftStatus: "PENDING",
          shiftDate: oRequestedShift.shiftDate,
        });
      }

      if (!isFromAllDone && localStorage.getItem("requestedShiftGroupId")) {
        //this is to delete the local storage if we are requesting it from All Done page
        //and local storage is set.Need to keep the local storage as user can also
        //refresh after requesting at all done page.
        localStorage.removeItem("requestedShiftGroupId");
      }
      return {
        ...state,
        myShifts,
        searchShiftResult: [...updateSearchShift],
        shiftRequestResponse: {
          ...state.shiftRequestResponse,
          isShiftRequest: true,

          shiftGroupId: action.payload.shiftRequestResponse.shiftGroupId,
        },
        check: true,
        techShiftDetails: isFromAllDone
          ? {
              ...state.techShiftDetails,
              shiftRequestedOn: currentTimeStamp,
              shiftRequestEndTimestamp,
            }
          : null,
        isShowConfirmRequestClosed: true,
      };
    case C.TECH_REQUEST_TECH_SHIFT_NOT_AVAILABLE:
      return {
        ...state,
        requestedHospOfShiftNotAvailable: action.payload.hospitalName,
        requestedDateOfShiftNotAvailable: action.payload.shiftDate,
        isShowShiftNotAvailableModal: true,
        shiftRequestResponseMessage: action.payload.message,
        shiftRequestResponse: {
          isShiftRequest: false,
          shiftGroupId: null,
        },
      };
    case C.CLOSE_SHIFT_NOT_AVAILABLE_MODAL:
      return {
        ...state,
        isShowShiftNotAvailableModal: false,
        requestedHospOfShiftNotAvailable: "",
        shiftRequestResponseMessage: "",
      };
    case C.TECH_REQUEST_TECH_SHIFT_FAIL:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        shiftRequestResponse: {
          ...state.shiftRequestResponse,
          isShiftRequest: false,
          shiftGroupId: null,
        },
      };
    case C.CLOSE_ERROR_MODAL:
      return {
        ...state,
        errorMessage: "",
      };
    case C.INITIALIZE_TECH_DASHBOARD:
      return {
        ...initialState,
      };
    case C.SHOW_FIRST_TIMER_MODAL:
      localStorage.setItem("requestedShiftGroupId", action.payload);
      return {
        ...state,
        isShowFirstTimerModal: true,
        requestFrom: action.requestFrom,
      };
    case C.HIDE_FIRST_TIMER_MODAL:
      return {
        ...state,
        isShowFirstTimerModal: false,
        requestFrom: null,
        isSkippedW9Modal: 1,
      };
    case C.FETCH_FIRST_TIME_INFO_SUCCESS:
      return {
        ...state,
        techSignNowData: action.payload.techSignNowData,
      };
    case C.FETCH_FIRST_TIME_INFO_FAIL:
      return {
        ...state,
        techLicenseInformation: [],
        techSpecializationInformation: [],
      };
    // case C.UPDATE_TECH_BANKING_INFO_SUCCESS:
    //   return {
    //     ...state,
    //     bankingInfoSuccess: true,
    //     isShowFirstTimerModal: false,
    //     licenseInformationSuccess: false,
    //   };
    // case C.UPDATE_TECH_BANKING_INFO_FAIL:
    //   return {
    //     ...state,
    //     bankingInfoSuccess: false,
    //   };
    case C.GET_PENDING_TECH_RATINGS_SUCCESS:
      let currentPendingRatingIndex = state.currentPendingRatingIndex;
      let totalPendingRatingCount = state.totalPendingRatingCount;
      const isReviewsReloaded =
        totalPendingRatingCount - (state?.currentPendingRatingIndex - 1) !=
        action.payload.responseData?.totalCount
          ? true
          : false;
      if (!totalPendingRatingCount || isReviewsReloaded) {
        currentPendingRatingIndex = 1;
        totalPendingRatingCount = action.payload.responseData?.totalCount;
      }

      return {
        ...state,
        ratingModal: true,
        hospitalRatings: action.payload.responseData,
        currentPendingRatingIndex,
        totalPendingRatingCount,
      };

    case C.GET_PENDING_TECH_RATINGS_FAIL:
      return {
        ...state,
        ratingModal: false,
      };

    case C.UPDATE_CURRENT_HOSPITAL_RATING_BY_TECH_INDEX: {
      return {
        ...state,
        currentPendingRatingIndex: state.currentPendingRatingIndex + 1,
      };
    }

    case C.SUBMIT_TECH_W9_SUCCESS: {
      if (action.payload.data.length === 0) {
        return {
          ...state,
          isW9Success: false,
          W9ErrorMessage: action.payload.errorMessage,
        };
      } else {
        return {
          ...state,
          isW9Success: true,
        };
      }
    }
    case C.SUBMIT_TECH_W9_FAIL: {
      return {
        ...state,
        isW9Success: false,
        W9ErrorMessage: action.payload.errorMessage,
      };
    }
    case C.HIDE_TECH_W9_ERROR: {
      return {
        ...state,
        W9ErrorMessage: "",
      };
    }
    case C.RESET_TECH_W9_SUCCESS: {
      return {
        ...state,
        W9ErrorMessage: "",
        isW9Success: false,
      };
    }
    case C.SAVE_SEARCH_TECH_SHIFT_PARAMETERS: {
      return {
        ...state,
        searchParameters: action.payload,
      };
    }
    case C.SAVE_SHARED_SEARCH: {
      return {
        ...state,
        sharedSearch: action.payload,
      };
    }

    case C.GET_ALL_MY_TECH_SHIFTS_SUCCESS: {
      // confirmed (and completed) to update search result grid if pending shift is now confirmed
      const oConfirmedByShiftId = {};
      action.payload.myShifts.forEach((o) => {
        if (o.confirmedTechId) {
          oConfirmedByShiftId[o.shiftId] = true;
        }
      });
      const aSearchShiftResultConfirmedRemoved = [];
      state.searchShiftResult.forEach((o) => {
        if (!oConfirmedByShiftId[o.shiftId]) {
          const data = find(action.payload.myShifts, { shiftId: o.shiftId });
          const newData = { ...o, ...data };
          aSearchShiftResultConfirmedRemoved.push(newData);
        }
      });
      return {
        ...state,
        myShifts: action.payload.myShifts,
        nProbationShifts: action.payload.nProbationShifts,
        searchShiftResult: aSearchShiftResultConfirmedRemoved,
      };
    }

    case C.GET_ALL_MY_TECH_SHIFTS_FAIL: {
      return {
        ...state,
        myShifts: [],
        errorMessage: action.payload.errorMessage,
      };
    }
    case C.SUBMIT_TECH_BACKGROUND_SUCCESS: {
      return {
        ...state,
        isBackgroundSuccess: true,
      };
    }
    case C.SUBMIT_TECH_BACKGROUND_FAIL: {
      return {
        ...state,
        isBackgroundSuccess: false,
        backgroundErrorMessage: EXCEPTION_MESSAGE,
      };
    }
    case C.HIDE_TECH_BACKGROUND_ERROR: {
      return {
        ...state,
        backgroundErrorMessage: "",
      };
    }
    case C.FETCH_TECH_SHIFT_DETAILS_SUCCESS: {
      return {
        ...state,
        techShiftDetails: action.payload[0],
      };
    }
    case C.INITIALIZE_SEARCH_CLICK: {
      return {
        ...state,
        isSearchClicked: false,
      };
    }

    case C.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST:
    case C.RESET_TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION: {
      return {
        ...state,
        isRemoveConfirmedTechActionCompleted: false,
        isError: false,
        isReloadShifts: false,
      };
    }

    case C.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS: {
      return {
        ...state,
        isRemoveConfirmedTechActionCompleted: true,
        isError: false,
        reliabilityRating: action.payload.reliabilityRating,
        techStanding: action.payload.techStanding,
      };
    }

    case C.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL: {
      return {
        ...state,
        isRemoveConfirmedTechActionCompleted: true,
        isError: true,
        removeConfirmedTechErrorMsg: action.payload,
      };
    }

    case C.RELOAD_TECH_SHIFTS: {
      return {
        ...state,
        isReloadShifts: true,
      };
    }

    case C.FETCH_CALCULATE_TECH_SHIFT_PRICING:
      return {
        ...state,
        isCalculateShiftCostActionCompleted: false,
        isCalculatingShiftPricing: true,
      };

    case C.FETCH_CALCULATE_TECH_SHIFT_PRICING_SUCCESS:
      return {
        ...state,
        shiftAmount: action.payload.data.shiftAmount,
        techShiftAmount: action.payload.data.techShiftAmount,
        rateCardVersion: action.payload.data.version,
        shiftHourlyAmount: action.payload.data.shiftHourlyAmount,
        isCalculateShiftCostActionCompleted: true,
        isCalculatingShiftPricing: false,
      };

    case C.FETCH_CALCULATE_TECH_SHIFT_PRICING_FAIL:
      return {
        ...state,
        isCalculateShiftCostActionCompleted: true,
        isCalculatingShiftPricing: false,
      };

    case C.GET_EVENT_FOR_TECH_SUCCESS:
      return {
        ...state,
        eventListForTech: action.payload,
      };
    case C.GET_EVENT_FOR_TECH_FAIL:
      return {
        ...state,
        eventListForTech: [],
      };
    case C.GET_EVENT_FOR_TECH:
      return {
        ...state,
        payload: action.payload,
      };

    // case C.GET_TECH_PAYMENT_INFO_SUCCESS:
    //   return {
    //     ...state,
    //     techPaymentInfo: action.payload,
    //   };

    // case C.GET_TECH_PAYMENT_INFO_FAIL:
    //   return {
    //     ...state,
    //     techPaymentInfo: {},
    //   };
    case C.UPDATE_TECH_DETAILS_PAYMENT_INFO:
      return { ...state, isUpdateInfoPayment: null };
    case C.UPDATE_TECH_DETAILS_PAYMENT_INFO_SUCCESS:
      return { ...state, isUpdateInfoPayment: true };
    case C.UPDATE_TECH_DETAILS_PAYMENT_INFO_FAIL:
      return { ...state, isUpdateInfoPayment: false };
    case C.RESET_TECH_DETAILS_PAYMENT_INFO:
      return { ...state, isUpdateInfoPayment: null };
    case C.GET_TECH_PROMO_TRACKING_BY_USER_ID_SUCCESS:
      return {
        ...state,
        techPromoTracking: action.payload,
      };
    case C.GET_TECH_PROMO_TRACKING_BY_USER_ID_FAIL:
      return {
        ...state,
        techPromoTracking: [],
      };
    case C.CREATE_TECH_PROMO_TRACKING_SUCCESS:
      return {
        ...state,
        isCreateTechPromoTrackingSuccess: action.payload,
      };
    case C.CREATE_TECH_PROMO_TRACKING_FAIL:
      return {
        ...state,
        isCreateTechPromoTrackingSuccess: false,
        createPromoTrackingError: action.payload,
      };
    case C.CLEAR_TECH_PROMO_TRACKING_DATA: {
      return {
        ...state,
        isCreateTechPromoTrackingSuccess: false,
        createPromoTrackingError: null,
      };
    }
    case C.GET_TECH_COMPLETED_PROMOS_SUCCESS:
      return {
        ...state,
        techCompletedPromos: action.payload,
        isTechCompletedPromosLoaded: true,
      };
    case C.GET_TECH_COMPLETED_PROMOS_FAIL:
      return {
        ...state,
        techCompletedPromos: [],
        isTechCompletedPromosLoaded: false,
      };
    case C.GET_TECH_CONFIRMED_SHIFT_DATA_SUCCESS:
      return {
        ...state,
        isFirstConfirmedShift: action.payload?.isFirstConfirmedShift,
        isFirstShiftRated: action.payload?.isFirstShiftRated,
        isSkippedW9Modal: action.payload?.isSkippedW9Modal,
      };
    case C.GET_TECH_CONFIRMED_SHIFT_DATA_FAIL:
      return {
        ...state,
        isFirstConfirmedShift: null,
        isFirstShiftRated: null,
        isSkippedW9Modal: null,
      };
    case C.TECH_SKIP_W9_MODAL_SUCCESS:
      return {
        ...state,
        isSkippedW9Modal: 1,
      };
    case C.SHOW_FIRST_TIMER_MODAL_NO_SKIP:
      return {
        ...state,
        isShowFirstTimerModal: true,
        isNoSkipFirstTimerModal: true,
      };
    case C.SAVE_SHIFT_COUNT_SUCCESS:
      return {
        ...state,
        sortedShiftCount: action.payload.totalCount,
      };
    case C.SAVE_SHIFT_COUNT_FAIL:
      return {
        ...state,
        sortedShiftCount: null,
      };
    case C.SET_DASHBOARD_VIEW_SUCCESS:
      return {
        ...state,
        dashboardView: action.payload,
      };
    case C.SET_DASHBOARD_VIEW_FAIL:
      return {
        ...state,
        dashboardView: null,
      };
    case C.CLEAR_DASHBOARD_VIEW:
      return {
        ...state,
        dashboardView: null,
      };

    case C.GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS_TECH:
      return {
        ...state,
        isEligibleForNonRequestSurvey: action.payload,
      };

    case C.GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL_TECH:
    case C.TECH_SUBMIT_SURVEY:
      return {
        ...state,
        isEligibleForNonRequestSurvey: false,
      };
    case C.SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS:
      return {
        ...state,
        isInitialMapSortComplete: true,
      };

    case C.GET_TECH_PROMO_SHIFTS_SUCCESS:
      return {
        ...state,
        techPromoShifts: action.payload,
      };
    case C.TOGGLE_TECH_PROMO_SHIFTS_PANEL_OPEN:
      return {
        ...state,
        isShowPromoShiftPanel: true,
        selectedPromoName: action.payload,
      };
    case C.TOGGLE_TECH_PROMO_SHIFTS_PANEL_CLOSED:
      return {
        ...state,
        isShowPromoShiftPanel: false,
        selectedPromoName: null,
      };
    case C.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_OPEN: {
      return {
        ...state,
        isShowDeleteShiftRequestPanel: true,
        deleteShiftRequestDetails: action.payload,
      };
    }
    case C.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL_CLOSED: {
      return {
        ...state,
        isShowDeleteShiftRequestPanel: false,
        deleteShiftRequestDetails: null,
      };
    }
    case C.DELETE_TECH_SHIFT_REQUEST_SUCCESS: {
      return {
        ...state,
        cancelledTechShiftRequestShiftId: action.payload,
        cancelledShiftRequestError: null,
        isShiftRequestCancelledSucess: true,
      };
    }
    case C.DELETE_TECH_SHIFT_REQUEST_FAIL: {
      return {
        ...state,
        cancelledTechShiftRequestShiftId: null,
        cancelledShiftRequestError: action.payload.message,
        isShiftRequestCancelledSucess: false,
      };
    }

    case C.RESET_TECH_SHIFT_REQUEST_CANCELLED_SUCCESS: {
      return {
        ...state,
        isShiftRequestCancelledSucess: false,
      };
    }

    case C.CANCEL_CONFIRMED_TECH_SHIFT_SUCCESS: {
      return {
        ...state,
        isConfirmedShiftCancelledSuccess: true,
        cancelledShiftDate: state.cancelShiftData?.shiftDate,
      };
    }

    case C.CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS: {
      return {
        ...state,
        isShiftCancellationRequestSuccess: true,
      };
    }

    case C.RESET_CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS: {
      return {
        ...state,
        isShiftCancellationRequestSuccess: false,
      };
    }

    case C.TOGGLE_TECH_CANCEL_CONFIRMED_SHIFT_PANEL: {
      return {
        ...state,
        cancelShiftData: action.payload.isShow ? action.payload.cancelShiftDetails : {},
        isShowCancelConfirmedShiftPanel: action.payload.isShow,
      };
    }

    case C.RESET_CANCELLED_TECH_SHIFT_SUCCESS_FLAG: {
      return {
        ...state,
        isConfirmedShiftCancelledSuccess: false,
        cancelledShiftDate: null,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default techDashboardReducer;
