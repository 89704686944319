import React from "react";

import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";
import { NestedTabs, TabNameObj } from "../NestedTabs";

import {
  ButtonContainer,
  CloseIconContainer,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalSize,
  ModalType,
  PanelContentContainer,
  StyledModal,
} from "./ModalStyles";

export interface ModalProps {
  size?: ModalSize;
  showModal?: boolean;
  onCloseModal?: () => void;
  topPosition?: number;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  centerButtons?: boolean;
  defaultSelectedTabIndex?: number;
  onClickTab?: (e: any) => void;
  header?: string;
  body?: React.ReactNode;
  $modalType?: ModalType;
  $noClose?: boolean;
  $tabNameArr?: TabNameObj[];
  $noPadding?: boolean;
  "data-testid"?: string;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    size,
    showModal,
    onCloseModal,
    $modalType,
    primaryButtonProps,
    secondaryButtonProps,
    defaultSelectedTabIndex,
    onClickTab,
    header,
    body,
    centerButtons,
    $noClose,
    $tabNameArr,
    $noPadding,
    "data-testid": dataTestId,
  } = props;

  const modalButtons = (
    <ButtonContainer
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      $tabNameArr={$tabNameArr}
      centerButtons={centerButtons}
    >
      {primaryButtonProps ? <Button buttonType="primary" {...primaryButtonProps} /> : null}
      {secondaryButtonProps ? <Button buttonType="secondary" {...secondaryButtonProps} /> : null}
    </ButtonContainer>
  );

  const newTabNameArr: TabNameObj[] | undefined = $tabNameArr?.map((tab: TabNameObj) => {
    return {
      ...tab,
      panelContent: (
        <PanelContentContainer>
          {tab.panelContent}
          {modalButtons}
        </PanelContentContainer>
      ),
    };
  });

  return (
    <StyledModal
      open={showModal}
      data-testid="modalContainer"
      closeIcon={false}
      footer={null}
      size={size}
      width="auto"
      zIndex={1050}
    >
      <ModalContent
        $modalType={$modalType}
        $tabNameArr={$tabNameArr}
        $noPadding={$noPadding}
        data-testid={dataTestId}
      >
        <CloseIconContainer $noClose={$noClose} $noPadding={$noPadding} $tabNameArr={$tabNameArr}>
          <Icon name="Close" onClick={onCloseModal} testId="modalCloseIcon" />
        </CloseIconContainer>
        {(header || body) && (
          <ModalBody>
            {header && <ModalHeader>{header}</ModalHeader>}
            {body && <>{body}</>}
          </ModalBody>
        )}
        {newTabNameArr && defaultSelectedTabIndex && (
          <NestedTabs
            tabNameArr={newTabNameArr}
            defaultSelectedTabIndex={defaultSelectedTabIndex}
            onClickTab={onClickTab}
          />
        )}
        {header && body && modalButtons}
      </ModalContent>
    </StyledModal>
  );
};
