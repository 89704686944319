import React, { useCallback, useEffect } from "react";

import type { EventName } from "@roo-dev/event-tracking/src/Event";
import { MarketStatus, useGetMarketStatus } from "@Roo/api/user/useGetMarketStatus";
import { useGetServerFeatureFlagVariantKey } from "@Roo/api/user/useServerFeatureFlag";
import { useContactOwner } from "@Roo/api/vet/useContactOwner";
import { ReasonForRegistering } from "@Roo/constants/checkBoxConstants";
import { CalendlyPointOfEntry } from "@Roo/constants/posthogEventContextTypes";
import { FEATURE_FLAGS, FEATURE_FLAG_VARIANTS } from "@Roo/constants/postHogFeatureFlags";
import { UTM_SOURCE_EVENT } from "@Roo/EventSignUp/components/events/common/EventSignUpRedirectContainer";
import { HighCommitmentMeetYourRep } from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/SegmentedRegistration/HighCommitmentMeetYourRep";
import { LowCommitmentMeetYourRep } from "@Roo/RegistrationNew/Components/RegistrationMeetYourRooRep/SegmentedRegistration/LowCommitmentMeetYourRep";

import PageLoader from "../../../Common/PageLoader";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";
import { useProgressPercentEffect } from "../Common/hooks/useProgressPercentEffect";
import { useRedirect } from "../Common/hooks/useRedirect";
import { useRegistrationContext } from "../Common/registrationContext";
import { REGISTRATION_PROGRESS_PERCENT } from "../Common/service";
import { RegistrationPageContainer } from "../RegistrationPageContainer/RegistrationPageContainer";

export const CALENDLY_CALL_PROMO_AMOUNT = 30;

export const RegistrationMeetYourRooRep = () => {
  const {
    registrationContext: { entityId, utmSource, userId, reasonForRegistering },
  } = useRegistrationContext();
  const { redirectToCalendly, redirectToVetPreferences } = useRedirect();
  useProgressPercentEffect(REGISTRATION_PROGRESS_PERCENT.MeetRooRep);

  const { data } = useContactOwner({ vetId: entityId });
  const { firstName, lastName, photo } = data?.data ?? {};

  const isHighCommitment = reasonForRegistering === ReasonForRegistering.FindingTheRightShift;

  const isFromEvent = utmSource === UTM_SOURCE_EVENT;
  const showBackButton = !isFromEvent;

  const { data: marketStatus } = useGetMarketStatus(userId);
  const userInMarket = marketStatus === MarketStatus.IN_MARKET;
  const {
    data: calendlyCallPromoVariantKey,
    isLoading: isLoadingFeatureFlag,
    isFetching: isFetchingFeatureFlag,
  } = useGetServerFeatureFlagVariantKey(userId, FEATURE_FLAGS.CALENDLY_CALL_PROMO, userInMarket);
  const shouldWaitForFeatureFlagResponse =
    isFetchingFeatureFlag || (isLoadingFeatureFlag && userInMarket);
  const isCalendlyCallPromoVariantKeyTest =
    calendlyCallPromoVariantKey === FEATURE_FLAG_VARIANTS.TEST;
  const isCalendlyCallPromoEnabled = userInMarket && isCalendlyCallPromoVariantKeyTest;

  const sendEvent = useCallback(
    (event: EventName, eventType: typeof Event.Type.CLICK | typeof Event.Type.IMPRESSION) => {
      EventTracker.send({
        eventName: event,
        eventType: eventType,
        entityType: Event.Entity.VET,
        entityId,
        context: { pointOfEntry: CalendlyPointOfEntry.RegistrationMeetYourRooRep },
      });
    },
    [entityId]
  );

  const handleScheduleCall = () => {
    sendEvent(Event.Name.VET_SCHEDULE_ACTIVATION_CALL_START, Event.Type.CLICK);

    redirectToCalendly({ shiftPreview: true });
  };

  useEffect(() => {
    if (entityId) {
      sendEvent(Event.Name.VET_VIEW_ACTIVATION_CALL, Event.Type.IMPRESSION);
    }
  }, [sendEvent, entityId]);

  if (shouldWaitForFeatureFlagResponse) {
    return <PageLoader />;
  }

  return (
    <RegistrationPageContainer
      linkProps={
        showBackButton
          ? {
              children: "Back to Preferences",
              onClick: () => {
                redirectToVetPreferences({ highCommitment: isHighCommitment });
              },
            }
          : undefined
      }
    >
      {isHighCommitment ? (
        <HighCommitmentMeetYourRep
          handleScheduleCall={handleScheduleCall}
          isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled}
          firstName={firstName}
          lastName={lastName}
          photo={photo}
        />
      ) : (
        <LowCommitmentMeetYourRep
          handleScheduleCall={handleScheduleCall}
          isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled}
          firstName={firstName}
          lastName={lastName}
          photo={photo}
        />
      )}
    </RegistrationPageContainer>
  );
};
