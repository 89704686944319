import { createGlobalStyle } from "styled-components";

import * as colorImport from "./constants/colors";
import { spacing } from "./constants/spacing";

const colors = colorImport as { [key: string]: string };

export const Theme = createGlobalStyle`
  :root {
      ${Object.keys(colors).map((key) => `--${key}: ${colors[key]};`)}
      ${Object.entries(spacing).map(([key, value]) => `--spacing${key.toUpperCase()}: ${value};`)}
  }
`;
