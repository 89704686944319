import { CarouselProps } from "antd";
import React, { FC, ReactNode } from "react";

import { Icon } from "../Icon";

import { CarouselStyled } from "./CarouselStyled";

export interface CarouselPropTypes extends CarouselProps {
  children: ReactNode;
  slidesToShow?: number;
}
export const Carousel: FC<CarouselPropTypes> = ({ children, ...props }) => {
  const slidesToShow = props.slidesToShow ?? 1;

  return (
    <CarouselStyled
      arrows
      $slidesToShow={slidesToShow} // Transient for custom styling
      {...props}
      prevArrow={<Icon name="KeyboardArrowLeft" size="xl" />}
      nextArrow={<Icon name="KeyboardArrowRight" size="xl" />}
    >
      {children}
    </CarouselStyled>
  );
};
