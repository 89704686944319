import style from "styled-components";

import {
  blueBackground,
  grayBase,
  purpleBackground,
  purpleBase,
  spacingS,
} from "../../styles/constants";

export const Badge = style.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  margin-left: ${spacingS};
  padding: 0 6px;
`;

export const AlertBadge = style(Badge)`
  background-color: ${purpleBase};
  color: ${blueBackground};
`;

export const MutedAlertBadge = style(Badge)`
  background-color: ${purpleBackground};
  color: ${grayBase};
`;
