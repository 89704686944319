import React, { useEffect, useState } from "react";

import { AlertBadge, MutedAlertBadge } from "../Badge";
import { TabPanel } from "../TabPanel";

import { NestedTabContainer, TabAndPanelContainer } from "./NestedTabsContainerStyles";
import { TabNested } from "./TabNested";

export interface NestedTabContainerProps {
  tabNameArr: TabNameObj[];
  defaultSelectedTabIndex: number;
  onClickTab?: (e: any, selectedTabIndex: number) => void;
  children?: React.ReactNode;
  scrollbarSpace?: boolean;
}

export interface TabNameObj {
  index: number;
  name: string;
  panelContent?: React.ReactNode;
  alertCount?: number;
  "data-testid"?: string;
}

export const NestedTabs: React.FC<NestedTabContainerProps> = (props) => {
  const { tabNameArr, defaultSelectedTabIndex, onClickTab } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultSelectedTabIndex);

  useEffect(() => {
    setSelectedTabIndex(defaultSelectedTabIndex);
  }, [defaultSelectedTabIndex]);

  return (
    <TabAndPanelContainer>
      <NestedTabContainer>
        <>
          {tabNameArr?.map((tabNameObj: TabNameObj) => {
            const { index, name, alertCount } = tabNameObj;
            const testId =
              typeof tabNameObj["data-testid"] === "undefined"
                ? "tabName"
                : tabNameObj["data-testid"];

            return (
              <TabNested
                key={index}
                isSelected={index === selectedTabIndex}
                onClick={(e: any) => {
                  if (tabNameArr.length > 1) {
                    setSelectedTabIndex(index);
                    if (onClickTab) onClickTab(e, index);
                  } else return null;
                }}
                index={index}
                data-testid={testId}
                disabled={tabNameArr.length === 1}
              >
                {name}
                {alertCount !== undefined &&
                  (alertCount === 0 ? (
                    <MutedAlertBadge>{alertCount}</MutedAlertBadge>
                  ) : (
                    <AlertBadge>{alertCount}</AlertBadge>
                  ))}
              </TabNested>
            );
          })}
        </>
      </NestedTabContainer>
      {tabNameArr?.map((tabNameObj: TabNameObj) => {
        const { index, panelContent } = tabNameObj;
        const testId =
          typeof tabNameObj["data-testid"] === "undefined" ? "tabName" : tabNameObj["data-testid"];
        return (
          panelContent && (
            <TabPanel
              key={index}
              isSelected={index === selectedTabIndex}
              scrollbarSpace={props.scrollbarSpace}
              data-testid={testId}
            >
              {panelContent}
            </TabPanel>
          )
        );
      })}
    </TabAndPanelContainer>
  );
};
