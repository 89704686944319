import { styled } from "styled-components";
import { smallMediaBreakpoint, spacingM, spacingS } from "@RooUI";

export const ImprovedFiltersGroupWrapper = styled.div`
  width: 544px;

  & > div {
    & > div {
      gap: ${spacingS} !important;
      margin-top: 6px;
      flex-direction: column;
      align-items: start;
    }
  }
  & > div:not(:last-child) {
    margin-bottom: ${spacingM};
  }

  @media (max-width: ${smallMediaBreakpoint}) {
    width: 264px;
  }
`;
