import React from "react";

export const MdQuote = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g>
      <circle cx="12" cy="12" r="12" fill={"#3747AB"} />
      <path
        d="M10.8421 14.7031H8L10.6749 8.70312H12.4861L10.8421 14.7031ZM15.356 14.7031H12.5139L15.1889 8.70312H17L15.356 14.7031Z"
        fill="white"
      />
    </g>
  </svg>
);

MdQuote.isCustomSvg = true;
