import {
  Avatar,
  Container,
  Icon,
  SmallText,
  blueLightest,
  grayBase,
  spacingM,
  spacingS,
} from "@RooUI";
import React from "react";
import styled from "styled-components";

import fallbackAvatar from "../../../../static/images/fallback_avatar.svg";
import registrationData from "../../../../locales/en/registration.json";
import VetPic from "../../../../static/images/roo-vet.png";

interface RooRepProps {
  highCommitment: boolean;
  photo: string | null | undefined;
}

export const RooRep = ({ highCommitment, photo }: RooRepProps) => {
  const testimonial = highCommitment
    ? registrationData.hcMeetYourRooRep.testimonial
    : registrationData.lcMeetYourRooRep.testimonial;

  const formattedTestimonial = `${testimonial.text}\n— ${testimonial.name}, ${testimonial.location}`;

  return (
    <Container
      backgroundColor={blueLightest}
      borderColor={blueLightest}
      data-testid="rooRep"
      style={{ padding: spacingM }}
    >
      <StyledTopRow>
        <StyledPhotoGroup>
          <StyledVetPic src={VetPic} />
          {photo ? (
            <Avatar
              size={40}
              shape="circle"
              src={photo}
              data-testid="repPhoto"
              style={{ marginLeft: `-${spacingS}` }}
            />
          ) : (
            <StyledRooLogo src={fallbackAvatar} data-testid="fallbackPhoto" />
          )}
        </StyledPhotoGroup>
        <Icon name="Quote" size="l" />
      </StyledTopRow>
      <StyledTestimonial color={grayBase} data-testid="testimonial">
        {formattedTestimonial}
      </StyledTestimonial>
    </Container>
  );
};

const StyledRooLogo = styled.img`
  height: 40px;
  width: 40px;
  margin-left: -${spacingS};
`;

const StyledVetPic = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const StyledTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPhotoGroup = styled.div`
  display: flex;
`;

const StyledTestimonial = styled(SmallText)`
  flex: 1;
  white-space: pre-line;
  margin-top: ${spacingS};
`;
