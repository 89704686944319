import * as C from "../../constants/techConstants";

export function searchShiftRequestAction(value) {
  return {
    type: C.SEARCH_TECH_SHIFT_REQUEST,
    payload: value,
  };
}

export function saveSharedSearch(data) {
  return {
    type: C.SAVE_SHARED_SEARCH,
    payload: data,
  };
}

export function getLinkedShiftAction(shiftGroupId) {
  return {
    type: C.GET_LINKED_TECH_SHIFT_REQUEST,
    payload: shiftGroupId,
  };
}

export function setCalendarViewAction() {
  return {
    type: C.SET_TECH_DASHBOARD_CALENDAR_VIEW,
  };
}

export function setGridViewAction() {
  return {
    type: C.SET_TECH_DASHBOARD_GRID_VIEW,
  };
}

export function setMapViewAction() {
  return {
    type: C.SET_TECH_DASHBOARD_MAP_VIEW,
  };
}

export function techRequestShiftRequestAction(
  value,
  shiftData = null,
  availableShifts = null,
  isFromAllDone = false
) {
  return {
    type: C.TECH_REQUEST_TECH_SHIFT_REQUEST,
    payload: value,
    shiftData: shiftData,
    availableShifts: availableShifts,
    isFromAllDone: isFromAllDone,
  };
}

export function initializeTechDashboardStateRequestAction() {
  return {
    type: C.INITIALIZE_TECH_DASHBOARD,
  };
}

export function showFirstTimerModalRequestAction(value, requestFrom = null) {
  return {
    type: C.SHOW_FIRST_TIMER_MODAL,
    payload: value,
    requestFrom: requestFrom,
  };
}

export function showFirstTimerModalNoSkip() {
  return {
    type: C.SHOW_FIRST_TIMER_MODAL_NO_SKIP,
  };
}

export function techSkipW9Modal(data) {
  return {
    type: C.TECH_SKIP_W9_MODAL,
    payload: data,
  };
}

export function getTechConfirmedShiftData(data) {
  return {
    type: C.GET_TECH_CONFIRMED_SHIFT_DATA,
    payload: data,
  };
}

export function hideFirstTimerModalRequestAction() {
  return {
    type: C.HIDE_FIRST_TIMER_MODAL,
  };
}

export function fetchFirstTimerInfo() {
  return {
    type: C.FETCH_TECH_FIRST_TIME_INFO_REQUEST,
  };
}

// export function updateTechFirstTimerInfoRequestAction( data ) {
//   return {
//     type: C.UPDATE_TECH_FIRST_TIMER_INFO_REQUEST,
//     payload: data
//   };
// }

// export function updateTechBankingInformationRequestAction(data) {
//   return {
//     type: C.UPDATE_TECH_BANKING_INFO_REQUEST,
//     payload: data,
//   };
// }

export function getPendingRatingsModal(data) {
  return {
    type: C.GET_PENDING_TECH_RATINGS_REQUEST,
    payload: data,
  };
}

export function closeRatingModal() {
  return {
    type: C.GET_PENDING_TECH_RATINGS_FAIL,
  };
}

export function closeErrorModal() {
  return {
    type: C.CLOSE_ERROR_MODAL,
  };
}

export function closeShiftNotAvailableModal() {
  return {
    type: C.CLOSE_SHIFT_NOT_AVAILABLE_MODAL,
  };
}

// export function getSignNowLink() {
//   return {
//     type: C.SIGN_NOW_LINK_REQUEST,
//   };
// }

export function submitTechw9(data) {
  return {
    type: C.SUBMIT_TECH_W9_REQUEST,
    payload: data,
  };
}

export function resetTechW9Success() {
  return {
    type: C.RESET_TECH_W9_SUCCESS,
  };
}

export function hideW9Error() {
  return {
    type: C.HIDE_TECH_W9_ERROR,
  };
}

export function saveSearchShiftParameters(data) {
  return {
    type: C.SAVE_SEARCH_TECH_SHIFT_PARAMETERS,
    payload: data,
  };
}

export function getMyShiftsAction(data) {
  return {
    type: C.GET_ALL_MY_TECH_SHIFTS_REQUEST,
    payload: data,
  };
}

export function initializeSearchResult() {
  return {
    type: C.INITIALIZE_SEARCH_RESULTS,
  };
}

export function submitBackgroundCheckInfo(data) {
  return {
    type: C.SUBMIT_TECH_BACKGROUND_REQUEST,
    payload: data,
  };
}

export function hideBackgroundError() {
  return {
    type: C.HIDE_TECH_BACKGROUND_ERROR,
  };
}

export function intializeSearchClickAction() {
  return {
    type: C.INITIALIZE_SEARCH_CLICK,
  };
}

export const getTechShiftDetailsAction = (shiftGroupId) => {
  return {
    type: C.FETCH_TECH_SHIFT_DETAILS_REQUEST,
    shiftGroupId: shiftGroupId,
  };
};

export const removeConfirmedTechAction = (data) => {
  return {
    type: C.TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST,
    payload: data,
  };
};

export const resetRemoveConfirmedTechAction = () => {
  return {
    type: C.RESET_TECH_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION,
  };
};

export const reloadTechShifs = () => {
  return {
    type: C.RELOAD_TECH_SHIFTS,
  };
};

export function fetchCalculateTechShiftPricingAction(data) {
  return {
    type: C.FETCH_CALCULATE_TECH_SHIFT_PRICING,
    payload: data,
  };
}

export function getEventForTechAction(data) {
  return {
    type: C.GET_EVENT_FOR_TECH,
    payload: data,
  };
}
export function getTechPaymentInfo() {
  return {
    type: C.GET_TECH_PAYMENT_INFO,
  };
}
export function updateTechDetailsPaymentInfo(data) {
  return {
    type: C.UPDATE_TECH_DETAILS_PAYMENT_INFO,
    payload: data,
  };
}

export function resetTechDetailsPaymentInfo() {
  return { type: C.RESET_TECH_DETAILS_PAYMENT_INFO };
}

export function createTechPromoTracking(data) {
  return {
    type: C.CREATE_TECH_PROMO_TRACKING,
    payload: data,
  };
}

export function clearTechPromoTrackingData() {
  return {
    type: C.CLEAR_TECH_PROMO_TRACKING_DATA,
  };
}

export function getTechActivePromosByUserId(data) {
  return {
    type: C.GET_TECH_PROMO_TRACKING_BY_USER_ID,
    payload: data,
  };
}

export function updateTechPromoMessageSeen(data) {
  return {
    type: C.UPDATE_TECH_PROMO_MESSAGE_SEEN,
    payload: data,
  };
}

export function getTechCompletedPromos(data) {
  return {
    type: C.GET_TECH_COMPLETED_PROMOS,
    payload: data,
  };
}

export function deferHospitalRating(data) {
  return {
    type: C.DEFER_HOSPITAL_RATING_BY_TECH,
    payload: data,
  };
}

export function deferAllHospitalRatings(data) {
  return {
    type: C.DEFER_ALL_HOSPITAL_RATINGS_BY_TECH,
    payload: data,
  };
}

export function saveShiftTotalCount(data) {
  return {
    type: C.SAVE_SHIFT_COUNT,
    payload: data,
  };
}

export function setDashboardView(data) {
  return {
    type: C.SET_DASHBOARD_VIEW,
    payload: data,
  };
}

export function clearDashboardView() {
  return {
    type: C.CLEAR_DASHBOARD_VIEW,
  };
}

export function getNonRequestSurveyEligibility(data) {
  return {
    type: C.GET_NON_REQUEST_SURVEY_ELIGIBILITY_TECH,
    payload: data,
  };
}

export function submitTechSurvey(data) {
  return {
    type: C.TECH_SUBMIT_SURVEY,
    payload: data,
  };
}

export function setIsInitialMapSortComplete() {
  return {
    type: C.SET_IS_INITIAL_MAP_SORT_COMPLETE,
  };
}

export function getTechPromoShifts(data) {
  return {
    type: C.GET_TECH_PROMO_SHIFTS,
    payload: data,
  };
}

export function toggleTechPromoShiftPanel(data) {
  return {
    type: C.TOGGLE_TECH_PROMO_SHIFTS_PANEL,
    payload: data,
  };
}

export function deleteTechShiftRequest(data) {
  return {
    type: C.DELETE_TECH_SHIFT_REQUEST,
    payload: data,
  };
}

export function resetTechShiftRequestCancelledSuccess() {
  return {
    type: C.RESET_TECH_SHIFT_REQUEST_CANCELLED_SUCCESS,
  };
}

export function toggleTechDeleteShiftRequestPanel(data) {
  return {
    type: C.TOGGLE_TECH_DELETE_SHIFT_REQUEST_PANEL,
    payload: data,
  };
}

export function cancelConfirmedTechShift(data) {
  return {
    type: C.CANCEL_CONFIRMED_TECH_SHIFT,
    payload: data,
  };
}

export function toggleTechCancelConfirmedShiftPanel(data) {
  return {
    type: C.TOGGLE_TECH_CANCEL_CONFIRMED_SHIFT_PANEL,
    payload: data,
  };
}

export function techShiftCancellationRequestSuccess() {
  return {
    type: C.CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS,
  };
}

export function resetTechShiftCancellationRequestSuccess() {
  return {
    type: C.RESET_CONFIRMED_TECH_SHIFT_CANCELLATION_REQUEST_SUCCESS,
  };
}

export function resetCancelConfirmedTechShiftFlag() {
  return {
    type: C.RESET_CANCELLED_TECH_SHIFT_SUCCESS_FLAG,
  };
}
