import React, { useEffect, useState } from "react";

import { blueDark600, disabledTextColor, redBase } from "../../styles/constants";
import {
  Control,
  CustomCheckLabel,
  IconLabel,
  InformationalDiv,
  InformationalInput,
  Input,
  InputContainer,
  SubText,
} from "../Checkbox/CustomCheckboxStyles";
import { Icon, IconProps } from "../Icon";

export interface CustomRadioProps {
  id?: string;
  label?: string;
  name?: string;
  value?: string | number;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  type?: "classic" | "informational";
  icon?: IconProps["name"];
  size?: "small" | "large";
  className?: string;
  style?: React.CSSProperties;
  onChange?: (event: any) => void;
  error?: string;
  subText?: string;
}
export const Radio: React.FC<CustomRadioProps> = (props) => {
  const {
    type = "classic",
    id,
    label = "",
    disabled = false,
    name = "",
    value = "",
    defaultChecked = false,
    checked,
    icon,
    className,
    style,
    error,
    onChange,
    subText,
  } = props;
  const [infoRadioChecked, setInfoRadioChecked] = useState(defaultChecked);
  useEffect(() => {
    if (checked !== undefined) {
      setInfoRadioChecked(checked);
    }
  }, [checked]);

  const handleRadioChange = (e: any) => {
    if (disabled) {
      return;
    }
    if (onChange) {
      // Patch the target value since we use a div, not an input
      if (type === "informational") {
        e.target.value = value;
      }
      onChange(e);
    }
  };

  let checkboxIconColor = blueDark600;
  if (disabled) {
    checkboxIconColor = disabledTextColor;
  } else if (error) {
    checkboxIconColor = redBase;
  } else if (checked) {
    checkboxIconColor = blueDark600;
  }

  return type === "classic" ? (
    <CustomCheckLabel $disabled={disabled} $error={!!error} className={className} style={style}>
      <InputContainer>
        <Input
          type="radio"
          id={id}
          name={name}
          checked={checked}
          defaultChecked={defaultChecked}
          value={value}
          disabled={disabled}
          onChange={handleRadioChange}
        />
        <Control $error={!!error} />
      </InputContainer>
      {label}
    </CustomCheckLabel>
  ) : (
    <InformationalDiv
      $disabled={disabled}
      $error={!!error}
      $subtextShown={!!subText}
      id={id}
      className={`${className} ${infoRadioChecked ? "checked" : "unchecked"}`}
      style={style}
      onClick={handleRadioChange}
    >
      <div className="content">
        {icon && (
          <div>
            <Icon name={icon} color={checkboxIconColor} />
          </div>
        )}
        <div className="text-column">
          <IconLabel $error={!!error} $disabled={disabled}>
            {label}
          </IconLabel>
          {subText && <SubText $disabled={disabled}>{subText}</SubText>}
        </div>
      </div>
      {subText && (
        <InputContainer>
          <InformationalInput
            type="radio"
            id={id}
            checked={infoRadioChecked}
            onChange={handleRadioChange}
            $error={!!error}
            $disabled={disabled}
          />
          <Control $error={!!error} />
        </InputContainer>
      )}
    </InformationalDiv>
  );
};
