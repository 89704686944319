import style from "styled-components";

export const NestedTabContainer = style.div`
    display: flex;
    flex-direction: row;
`;

export const TabAndPanelContainer = style.div`
    width: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow-y: auto;
    height: 100%;
`;
