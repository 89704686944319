import { StatusAlert } from "@RooUI";
import React from "react";

import { PendingEnterpriseStatusAlertContainer } from "./styles";

export const PendingEnterpriseHospitalBanner = ({ enterpriseName }: { enterpriseName: string }) => {
  return (
    <PendingEnterpriseStatusAlertContainer>
      <StatusAlert
        data-testid="pending-enterprise-alert"
        text={`Welcome to Roo! As a part of the ${enterpriseName} Enterprise Group, we've already started setting up your account. To start posting shifts, please join an onboarding call where we'll guide you through platform features and answer any questions you may have.`}
        statusIcon="Campaign"
      />
    </PendingEnterpriseStatusAlertContainer>
  );
};
