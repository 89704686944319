import { BodyText, Button, FlexLayout, Modal, spacingL, spacingXL } from "@RooUI";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { EventName } from "@roo-dev/event-tracking/src/Event";

import { CalendlyPointOfEntry } from "../../constants/posthogEventContextTypes";
import { isMobileDevice } from "../../helpers/jsUtility";
import { formatPrice } from "../../helpers/textUtility";
import { CALENDLY_CALL_PROMO_AMOUNT } from "../../RegistrationNew/Components/RegistrationMeetYourRooRep/RegistrationMeetYourRooRep";
import { ModalProps } from "../../RooUI/components/Modal";
import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";
import { MeetYourRooRep } from "../MeetYourRooRep/MeetYourRooRep";
import { useRooTranslation } from "../Wrappers/useRooTranslation";

const FlexWithBodyText = styled(FlexLayout)`
  ${BodyText};
  margin-bottom: ${spacingXL};
`;

interface AccountActivationCallModalProps extends ModalProps {
  vetId: number;
  pointOfEntry: CalendlyPointOfEntry;
  isCalendlyCallPromoEnabled: boolean;
}

export const AccountActivationCallModal = ({
  vetId,
  pointOfEntry,
  isCalendlyCallPromoEnabled,
  onCloseModal,
  showModal,
  "data-testid": dataTestId = "activationCallModal",
  ...rest
}: AccountActivationCallModalProps) => {
  const history = useHistory();
  const questionsAboutRooText =
    "We're here to answer all your questions and help you get the most out of Roo — we can even modify shifts to meet your needs. Can't wait to meet you!";
  const isMobile = isMobileDevice();
  const { t } = useRooTranslation();

  const sendEvent = (event: EventName) => {
    EventTracker.send({
      eventName: event,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.VET,
      entityId: vetId,
      context: { pointOfEntry },
    });
  };

  const handleScheduleClick = () => {
    sendEvent(Event.Name.VET_SCHEDULE_ACTIVATION_CALL_START);
    const searchParams = new URLSearchParams({ pointOfEntry });
    history.push(`/vet/calendly?${searchParams.toString()}`);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const handleJustLookingClick = () => {
    if (onCloseModal) {
      sendEvent(Event.Name.VET_SKIP_ACTIVATION_CALL);
      onCloseModal();
    }
  };

  const formattedPromoAmount = formatPrice(CALENDLY_CALL_PROMO_AMOUNT);
  const modalHeaderText = isCalendlyCallPromoEnabled
    ? t("registration.meetYourRooRep.promo.accountActivationCall.title", {
        promoAmount: formattedPromoAmount,
      })
    : t("registration.meetYourRooRep.accountActivationCall.title");
  const modalBodyText = isCalendlyCallPromoEnabled
    ? t("registration.meetYourRooRep.promo.accountActivationCall.description", {
        promoAmount: formattedPromoAmount,
      })
    : t("registration.meetYourRooRep.accountActivationCall.description");

  return (
    <Modal
      header={modalHeaderText}
      onCloseModal={onCloseModal}
      showModal={showModal}
      data-testid={dataTestId}
      size="l"
      body={
        <>
          {/* Header section */}
          <FlexWithBodyText vertical gap="xl">
            {modalBodyText}
          </FlexWithBodyText>

          {/* Middle section */}
          <MeetYourRooRep
            vetId={vetId}
            marginBottom={spacingL}
            questionsAboutRooText={questionsAboutRooText}
            isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled}
          />

          {/* Action section */}
          <FlexLayout gap="m" justify="center" vertical={isMobile}>
            <Button
              size="small"
              buttonType="primary"
              title="Schedule Activation Call"
              testId="scheduleActivationBtn"
              onClick={handleScheduleClick}
            />
            <Button
              size="small"
              title="I'm Just Looking"
              testId="justLookingBtn"
              onClick={handleJustLookingClick}
            />
          </FlexLayout>
        </>
      }
      {...rest}
    />
  );
};
