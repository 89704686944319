import { MeetYourRep } from "@Roo/api/vet";
import { patchVet } from "@Roo/api/vet/patchVet";
import { LowCommitmentTopicsToDiscuss } from "@Roo/constants/checkBoxConstants";
import { useRedirect } from "@Roo/RegistrationNew/Components/Common/hooks/useRedirect";
import { useRegistrationContext } from "@Roo/RegistrationNew/Components/Common/registrationContext";

export const useValidate = () => {
  const validate = (form: MeetYourRep) => {
    const errors: {
      topicsToDiscussOpenResponse?: string;
    } = {};

    if (
      form.topicsToDiscuss.some(
        (option) => option.checked && option.id === LowCommitmentTopicsToDiscuss.Other
      ) &&
      !form.topicsToDiscussOpenResponse
    ) {
      errors.topicsToDiscussOpenResponse = "Details are required";
    }

    return errors;
  };
  return { validate };
};

export const useSave = () => {
  const { redirectToCalendly } = useRedirect();
  const { registrationContext, setRegistrationContext } = useRegistrationContext();
  const { userId, entityId } = registrationContext;

  const save = async (form: MeetYourRep): Promise<void> => {
    setRegistrationContext({
      registrationContext: {
        ...registrationContext,
        ...form,
      },
    });

    const topicsToDiscuss = (form.topicsToDiscuss ?? [])
      .filter((option) => option.checked)
      .map((checkedOption) => checkedOption.id);

    const vetData: any = {
      userId: userId,
      vetId: entityId,
      topicsToDiscuss,
      topicsToDiscussOpenResponse: form.topicsToDiscussOpenResponse,
    };
    await patchVet(vetData);

    redirectToCalendly();
  };
  return { save };
};
